.react-datepicker {
  font-family: 'NotoSans';
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.3rem;
  border: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff;
  border-top-left-radius: 0.3rem;
  border-bottom: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #1991eb;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.react-datepicker__navigation--previous {
  left: 30px;
  top: 25px;
  border-right-color: black;
}

.react-datepicker__navigation--next {
  right: 30px;
  top: 25px;
  border-left-color: black;
}

.react-datepicker__month-container {
  float: left;
  border-right: 1px solid #dddedf;
  padding: 16px 16px 0 16px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #a6abbe;
}

.react-datepicker__day-name {
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0.166rem;
  color: #a6abbe;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: #43485c;
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #ffcc11;
  color: #43485c;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #fff8e1;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #ffcc11 !important;
  color: #fff;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #ffcc11;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #fff8e1;
  color: #43485c;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #ffcc11;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #fff;
  color: #43485c;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: #fff8e1;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
