@font-face {
  font-family: 'noto-sans-bold';
  font-style: 'bold';
  font-display: swap;
  src: url('./assets/fonts/noto-sans/noto-sans-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'noto-sans-bold-italic';
  font-style: 'bold-italic';
  font-display: swap;
  src: url('./assets/fonts/noto-sans/noto-sans-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'noto-sans-italic';
  font-style: 'italic';
  font-display: swap;
  src: url('./assets/fonts/noto-sans/noto-sans-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'NotoSans';
  font-style: 'regular';
  font-display: swap;
  src: url('./assets/fonts/noto-sans/noto-sans.woff2') format('woff2');
}
